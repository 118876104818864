<template>
  <div
    class="d-flex content-center min-vh-100 w-100 login-page"
    :style="background ? `background-image: url('${background.url}')` : ''"
  >
    <CModal
      :title="modal.title"
      :color="modal.color"
      :show.sync="modal.show"
      :centered="true"
      class="text-left"
    >
      {{ modal.message }}
    </CModal>

    <CContainer>
      <CRow class="d-flex content-center">
        <CCol sm="12" md="12" lg="6">
          <CCardGroup>
            <CCard class="p-4" accent-color="info">
              <CCardBody>
                <CForm @submit.prevent="send">
                  <div class="d-inline-block w-100 mb-4">
                    <div
                      class="card-image d-flex justify-content-center w-100 logo-box"
                    >
                      <img
                        v-if="coloredLogo"
                        :src="coloredLogo.url"
                        :alt="coloredLogo.title"
                      />
                      <img
                        v-else-if="loadedLogo"
                        src="@/assets/img/logo.svg"
                        alt="ow-logo-black"
                      />
                    </div>
                    <small class="text-muted d-inline-block w-100 mt-3">{{
                      showForgotPassword
                        ? 'Enviaremos um e-mail de recuperação de senha para sua caixa de entrada'
                        : 'Acesse o painel administrativo utilizando seu e-mail e senha.'
                    }}</small>
                  </div>
                  <CInput
                    v-model="form.email"
                    placeholder="Digite o seu e-mail"
                    type="email"
                    required
                    :disabled="processing"
                  ></CInput>
                  <CInput
                    v-if="!showForgotPassword"
                    v-model="form.password"
                    placeholder="Digite a sua senha"
                    type="password"
                    required
                    :disabled="processing"
                  ></CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton
                        color="link"
                        type="button"
                        style="
                          padding-left: 0px !important;
                          padding-right: 0px !important;
                        "
                        @click="
                          () => (showForgotPassword = !showForgotPassword)
                        "
                      >
                        {{
                          showForgotPassword
                            ? 'Voltar para login'
                            : 'Esqueceu sua senha?'
                        }}
                      </CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton
                        color="info"
                        class="px-4"
                        type="submit"
                        :disabled="processing"
                      >
                        <template v-if="processing">
                          {{
                            showForgotPassword ? 'Enviando...' : 'Aguarde...'
                          }}
                        </template>
                        <template v-else>
                          {{ showForgotPassword ? 'Enviar' : 'Entrar' }}
                        </template>
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AuthService from '@/services/auth.service'
import Cookies from 'js-cookie'

const defaultForm = () => ({
  email: '',
  password: ''
})

export default {
  metaInfo: {
    title: 'Login',
    titleTemplate: ' %s - Manager - Rodonaves Iveco'
  },

  data() {
    return {
      form: defaultForm(),
      token: null,
      modal: {
        show: false,
        title: '',
        message: ''
      },
      loadedLogo: false,
      showForgotPassword: false,
      processing: false,
      logged: false
    }
  },

  computed: {
    ...mapGetters('settings', ['coloredLogo', 'background']),
    ...mapGetters('auth', ['loggedIn'])
  },

  async created() {
    this.logged = Cookies.getJSON('base:user')
    this.userHaveTwoFactorAuth = Cookies.getJSON('base:userHaveTwoFactorAuth')
    if (this.userHaveTwoFactorAuth) {
      return this.$router.push('/validate-token')
    }
    if (this.logged) {
      return this.$router.push('/dashboard')
    }
    await this.getLogo()
  },

  methods: {
    ...mapActions('auth', ['login', 'logout']),

    async getLogo() {
      await this.$store.dispatch('settings/loadMedias')
      this.loadedLogo = true
    },

    send() {
      this.showForgotPassword ? this.forgetPassword() : this.loginUser()
    },

    async forgetPassword() {
      this.processing = true

      const response = await AuthService.passReset(this.form.email)

      this.processing = false

      if (response === 200) {
        this.modal = {
          show: true,
          title: 'Sucesso',
          message:
            'Um link para alteração da sua senha foi enviada para seu e-mail.',
          color: 'success'
        }
      } else if (response === 403) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Já existe um token valido, por favor verifique seu e-mail',
          color: 'danger'
        }
      } else {
        this.modal = {
          show: true,
          title: 'Erro',
          message:
            'O e-mail preenchido não existe em nossa base de dados, por favor tente outro e-mail.',
          color: 'danger'
        }
      }
    },

    async loginUser() {
      this.processing = true

      try {
        const user = {
          login: this.form.email,
          password: this.form.password
        }

        const response = await AuthService.login(user)
        const redirect = response.user_have_two_factor_auth
          ? '/validate-token'
          : '/dashboard'

        this.$router.push(redirect)
        this.login(response.token)
      } catch (error) {
        this.logout()
        const message =
          error.response.data.message ??
          'Ocorreu um erro, por favor tente novamente mais tarde.'

        this.modal = {
          show: true,
          title: 'Erro',
          message,
          color: 'danger'
        }
      } finally {
        this.processing = false
      }
    }
  }
}
</script>

<style lang="scss">
.login-page {
  background-image: linear-gradient(
    to left bottom,
    #000000,
    #101010,
    #1b1b1b,
    #252525,
    #2f2f2f
  );
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .card {
    border-left: none;
    border-bottom: none;
    border-right: none;

    .card-image {
      img {
        max-height: 80px;
      }
    }

    .form-group {
      .input-group {
        .input-group-text {
          background: $info;
          border-color: $info;
          color: $white;
        }
      }
    }
  }

  .modal {
    .modal-footer {
      display: none;
    }
  }
}

.logo-box {
  min-height: 40px;
}
</style>
